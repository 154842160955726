import { AddressFragment } from "@contexts/LocationDetailContext/LocationDetailContext.generated";
import TextField, {
  TextFieldProps,
} from "@src/deprecatedDesignSystem/components/TextField";
import { KeyStrokeConfig } from "@hooks/useKeystrokes";
import { AddressInput } from "@src/types.generated";
import { StyleDeclaration } from "aphrodite";
import { FC, useEffect, useRef, useState } from "react";

type ExtendedAddressInput = AddressInput & {
  timezone?: string;
};

type Props = {
  onAddressChange: (address: ExtendedAddressInput) => void;
  initialAddress?: ExtendedAddressInput;
  keyStrokeConfig?: KeyStrokeConfig;
  inputLabel?: string;
  inputStyleDeclaration?: StyleDeclaration;
  textFieldProps?: Partial<TextFieldProps>;
  hasValidationError?: boolean;
};

const AddressField: FC<Props> = ({
  onAddressChange,
  initialAddress,
  keyStrokeConfig,
  inputLabel,
  inputStyleDeclaration,
  textFieldProps,
  hasValidationError,
}) => {
  const [query, setQuery] = useState(
    initialAddress ? addressInputToString(initialAddress) : "",
  );
  useEffect(() => {
    if (initialAddress) {
      setQuery(addressInputToString(initialAddress));
    }
  }, [initialAddress]);
  const autoCompleteRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!autoCompleteRef.current) {
      return;
    }
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["address"],
      },
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () => {
      const addressObject = autoComplete.getPlace();
      setQuery(addressObject.formatted_address || "");
      const addressInput = googlePlaceToAddress(addressObject);
      if (addressInput) {
        const { lat, lng } = addressInput;
        if (lat && lng) {
          const timestamp = Math.floor(Date.now() / 1000);
          fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`,
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "OK") {
                onAddressChange({
                  ...addressInput,
                  timezone: data.timeZoneId,
                });
              } else {
                onAddressChange(addressInput);
              }
            })
            .catch(() => {
              onAddressChange(addressInput);
            });
        } else {
          onAddressChange(addressInput);
        }
      }
    });
  }, [onAddressChange]);

  return (
    <TextField
      label={inputLabel}
      ref={autoCompleteRef}
      onTextChange={setQuery}
      keyStrokeConfig={keyStrokeConfig}
      placeholder="Search address..."
      inputStyleDeclaration={inputStyleDeclaration}
      text={query}
      {...textFieldProps}
      error={hasValidationError}
    />
  );
};

export default AddressField;

const googlePlaceToAddress = (
  place: google.maps.places.PlaceResult,
): AddressInput | null => {
  let lineOne = "";
  let lineTwo = "";
  let city = "";
  let state = "";
  let country = "";
  let postalCode = "";
  if (!place.address_components) {
    return null;
  }
  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        lineOne = `${component.long_name} ${lineOne}`;
        break;
      }

      case "route": {
        lineOne += component.short_name;
        break;
      }

      case "subpremise":
        lineTwo = component.long_name;
        break;

      case "postal_code": {
        postalCode = `${component.long_name}${postalCode}`;
        break;
      }

      case "postal_code_suffix": {
        postalCode = `${postalCode}-${component.long_name}`;
        break;
      }

      case "locality":
        city = component.long_name;
        break;

      case "sublocality_level_1":
        city = component.long_name;
        break;

      case "administrative_area_level_1": {
        state = component.short_name;
        break;
      }

      case "country":
        country = component.short_name;
        break;
    }
  }

  const lat = place.geometry?.location?.lat();
  const lng = place.geometry?.location?.lng();

  return {
    lineOne,
    lineTwo,
    city,
    state,
    country,
    postalCode,
    lat,
    lng,
  };
};

export const addressFragmentToString = (address: AddressFragment): string => {
  return `${address.lineOne}${address.lineTwo ? ` ${address.lineTwo}` : ""}, ${
    address.city
  }, ${address.usState},${
    address.postalCode ? ` ${address.postalCode},` : ""
  } ${address.country}`;
};

export const addressInputToString = (address: AddressInput): string => {
  return `${address.lineOne}${address.lineTwo ? ` ${address.lineTwo}` : ""}, ${
    address.city
  }, ${address.state},${address.postalCode ? ` ${address.postalCode},` : ""} ${
    address.country
  }`;
};
