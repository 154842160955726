import { FC, useCallback, UIEvent, useRef } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useAcceptedMediaTypes from "@src/components/libraryItemDetailPages/module/hooks/useAcceptedMediaTypes";
import LibraryItemRow from "@src/components/libraryItemDetailPages/module/createPathContentMembershipMenu/LibraryItemRow";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { TrainingResourceLibraryItemFragment } from "./AIGenerateUploadResources.generated";
import {
  getLibraryItemTrainingResourceAvatar,
  getLibraryItemTrainingResourceSubtitle,
  getLibraryItemTrainingResourceTitle,
} from "./utils";

type Props = {
  libraryItems: TrainingResourceLibraryItemFragment[];
  loading: boolean;
  hasMore: boolean;
  fetchMore: () => void;
  onClick: (selectedTrainingResourceId: string) => void;
};

const TrainingResourceLibraryItemsPaginatedList: FC<Props> = ({
  libraryItems,
  hasMore,
  fetchMore,
  onClick,
}) => {
  const { isMediaTypeAccepted } = useAcceptedMediaTypes();
  const listRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = useCallback(
    (event: UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const scrolledPastThreshold =
        target.scrollTop > (target.scrollHeight - target.clientHeight) * 0.7;
      if (scrolledPastThreshold) {
        fetchMore();
      }
    },
    [fetchMore],
  );

  return (
    <AutoLayout
      flex={1}
      ref={listRef}
      onScroll={handleScroll}
      alignSelf="stretch"
      direction="vertical"
      style={{ overflowY: "auto" }}
    >
      {libraryItems.map((x) => {
        const title = getLibraryItemTrainingResourceTitle(x);
        const allowedMediaType = isMediaTypeAccepted(
          x.trainingResource?.publishedVersion?.media?.mediaUrls?.en,
        );
        const subtitle =
          getLibraryItemTrainingResourceSubtitle(allowedMediaType);
        const avatar = getLibraryItemTrainingResourceAvatar(x);

        return (
          <LibraryItemRow
            key={x.trainingResource?.id}
            rowType="Row"
            title={title}
            subtitle={subtitle}
            isDisabled={!allowedMediaType}
            avatar={avatar}
            onClick={() => {
              if (x.trainingResource) {
                onClick(x.trainingResource.id);
              }
            }}
            style={{ padding: "4px 12px" }}
          />
        );
      })}
      {hasMore && (
        <AutoLayout
          flex={1}
          alignSelf="stretch"
          alignmentHorizontal="center"
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <Spinner size={24} color={deprecatedTones.blue9} />
        </AutoLayout>
      )}
    </AutoLayout>
  );
};

export default TrainingResourceLibraryItemsPaginatedList;
