import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { TrainingResourceLibraryItemFragment } from "./AIGenerateUploadResources.generated";
import { ReactElement } from "react";

export const getLibraryItemTrainingResourceTitle = (
  x: TrainingResourceLibraryItemFragment,
): string => {
  return x.name.en || "Untitled Resource";
};
export const getLibraryItemTrainingResourceSubtitle = (
  allowedMediaType: boolean,
): string => {
  return allowedMediaType ? "Resource" : "File type not supported";
};
export const getLibraryItemTrainingResourceAvatar = (
  x: TrainingResourceLibraryItemFragment,
): ReactElement => {
  return (
    <ContentAvatar
      size="32px"
      contentType="Resource"
      style={{ borderRadius: 8 }}
      coverImage={{
        imageUrls: x.trainingResource?.publishedVersion?.media?.imageUrls,
      }}
      media={x.trainingResource?.publishedVersion?.media}
      defaultBackgroundHashKey={x.id}
    />
  );
};
