import {
  isContentItemType,
  isEmployeeItemType,
  isLocationGroupTypeEnum,
  isLocationItemType,
  isRoleGroupItemType,
  isRoleItemType,
  isRouteItemType,
  isTagItemType,
} from "@src/components/layout/top-nav/utils";
import { getDefaultRouteItems } from "@src/components/ui/jumpToBar/hooks/routeItems";
import { JumpToItem } from "@src/components/ui/jumpToBar/hooks/useAllJumpToItems";
import useSearchJumpToItems from "@src/components/ui/jumpToBar/hooks/useSearchJumpToItems";
import ContentAvatar, {
  ContentType,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import GroupAvatar, {
  GroupType,
} from "@src/deprecatedDesignSystem/components/GroupAvatar";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import TagAvatar from "@src/deprecatedDesignSystem/components/TagAvatar";
import useBranding from "@src/hooks/useBranding";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { Ff } from "@src/types.generated";
import { CommandGroup, CommandItem, CommandSeparator } from "@src/ui/command";

interface Props {
  query: string;
  recentlyViewedItems: JumpToItem[];
  allItems: JumpToItem[];
  loading: boolean;
  onSelectItem: (item: JumpToItem) => void;
  itemDescription: (item: JumpToItem) => string;
  closePopover: () => void;
}

const JumpToBarItems: React.FC<Props> = (props) => {
  const showingRecentlyViewed = props.query.trim().length === 0;
  const filteredItems = useSearchJumpToItems(props.allItems, props.query);
  const aiChatEnabled = useFeatureFlag(Ff.AiChat);
  const { name } = useBranding();
  const renderItem = (item: JumpToItem, index: number) => {
    return (
      <CommandItem
        key={index}
        className="gap-2"
        onSelect={() => props.onSelectItem(item)}
        data-testid="jump-to-item-row"
        value={item.id.toString()}
      >
        <div className="flex flex-1 flex-col items-start gap-2">
          <div className="flex w-full items-center gap-2">
            {isEmployeeItemType(item) && typeof item.id === "number" ? (
              <PersonAvatar
                person={{
                  id: item.id,
                  name: item.name,
                }}
                size="24px"
              />
            ) : null}
            {isContentItemType(item) ? (
              <ContentAvatar
                size={"24px"}
                defaultBackgroundHashKey={item.id}
                contentType={item.type as ContentType}
                coverImage={
                  item.coverImage
                    ? item.coverImage
                    : {
                        imageUrls:
                          item.type === "Resource" && item.mediaUrl
                            ? { original: item.mediaUrl }
                            : undefined,
                      }
                }
              />
            ) : null}
            {isRouteItemType(item) && (
              <item.icon className="size-4 text-muted-foreground" />
            )}
            {isLocationItemType(item) ||
            isLocationGroupTypeEnum(item.type) ||
            isRoleItemType(item) ||
            isRoleGroupItemType(item) ? (
              <GroupAvatar size={"24px"} groupType={item.type as GroupType} />
            ) : null}
            {isTagItemType(item) && typeof item.id === "string" ? (
              <TagAvatar id={item.id} />
            ) : null}
            <span className="flex-1">
              {item.name === ""
                ? `Untitled ${item.type}`
                : item.name.replace(/\n/g, " ")}
            </span>
            <span className="text-right text-muted-foreground">
              {props.itemDescription(item).replace(/\n/g, " ")}
            </span>
          </div>
          {item.searchHeadline && (
            <div
              className="px-6 text-sm text-muted-foreground"
              dangerouslySetInnerHTML={{
                __html: item.searchHeadline,
              }}
            />
          )}
        </div>
      </CommandItem>
    );
  };
  if (showingRecentlyViewed) {
    return (
      <>
        <CommandGroup heading="Go to">
          {getDefaultRouteItems({
            includeChat: aiChatEnabled,
            brandName: name || "your content",
          }).map((item, index) => renderItem(item, index))}
        </CommandGroup>
        {props.recentlyViewedItems.length > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup heading="Recently Viewed">
              {props.recentlyViewedItems.map((item, index) =>
                renderItem(item, index),
              )}
            </CommandGroup>
          </>
        )}
      </>
    );
  }
  if (filteredItems.length === 0 && props.allItems.length > 0) {
    return (
      <EmptyState
        loading={props.loading}
        icon="search"
        title={`No results match "${props.query}"`}
      />
    );
  }
  const quickLinks = filteredItems.filter((item) => isRouteItemType(item));
  const results = filteredItems.filter((item) => !isRouteItemType(item));
  return (
    <>
      {quickLinks.length > 0 && (
        <CommandGroup heading="Go to">
          {quickLinks.map((item, index) => renderItem(item, index))}
        </CommandGroup>
      )}
      {results.length > 0 && (
        <CommandGroup heading="Results">
          {results.map((item, index) => renderItem(item, index))}
        </CommandGroup>
      )}
    </>
  );
};

export default JumpToBarItems;
