import QueryStatus from "@components/layout/QueryStatus";
import GroupPicker from "@components/modals/invitePeople/GroupPicker";
import {
  InviteQrCodeModalQuery,
  useCreateLocationInQrCodeModalMutation,
  useInviteQrCodeModalQuery,
} from "@components/modals/invitePeople/operations.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import useActionPermissions from "@hooks/useActionPermissions";
import { useTracking } from "@hooks/useTracking";
import DropdownMenu from "@src/deprecatedDesignSystem/components/DropdownMenu";
import { GraphqlOperations, GroupType } from "@src/types.generated";
import { StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";

type Locations = InviteQrCodeModalQuery["Locations"]["objects"];

type Location = InviteQrCodeModalQuery["Locations"]["objects"][0];

type Props = {
  initialLocationId: number | null;
};

const InviteQRCodePicker: FC<Props> = ({ initialLocationId }) => {
  const [locationId, setLocationId] = useState<number | null>(
    initialLocationId,
  );
  const { error, loading: queryLoading, data } = useInviteQrCodeModalQuery();
  const locations = useMemo(() => data?.Locations.objects || [], [data]);
  const selectedLocation = useMemo(
    () => locations.find((l: Location) => l.id === locationId),
    [locations, locationId],
  );
  if (!data) {
    return <QueryStatus loading={queryLoading} error={error} />;
  }
  return (
    <div className={css(styles.container)}>
      <Main
        selectedLocation={selectedLocation}
        locations={locations}
        setLocationId={setLocationId}
      />
    </div>
  );
};

type MainProps = {
  selectedLocation?: Location;
  locations: Locations;
  setLocationId: (id: number) => void;
};

const Main: FC<MainProps> = ({
  selectedLocation,
  locations,
  setLocationId,
}) => {
  const { userActionPermissions } = useActionPermissions();
  const { trackEvent } = useTracking();
  const [open, setOpen] = useState(false);
  const [createLocationMutation] = useCreateLocationInQrCodeModalMutation({
    refetchQueries: [GraphqlOperations.Query.InviteQRCodeModal],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.createLocation.location) {
        setLocationId(data.createLocation.location.id);
        trackEvent("created_location", { id: data.createLocation.location.id });
      }
    },
  });
  const createLocation = useCallback(
    (name: string) => {
      createLocationMutation({
        variables: {
          input: { name },
        },
      });
    },
    [createLocationMutation],
  );
  const [copySuccessVisible, setCopySuccessVisible] = useState(false);
  return (
    <>
      <GroupPicker
        groups={locations}
        groupType={GroupType.Location}
        setGroup={(group) => setLocationId(group.id)}
        group={selectedLocation}
        createGroup={
          userActionPermissions?.changeOrgSettings ? createLocation : undefined
        }
      />
      {selectedLocation == null || selectedLocation?.signupCode == null ? (
        <div className={css(styles.bodyContainer)}>
          <img
            src="/images/qr-code.png"
            className={css(styles.emptyImg)}
            alt="qr-code-empty-state"
          />
          <Text
            type="P2"
            multiline={true}
            fontWeight="Regular"
            color={deprecatedTones.gray8}
            styleDeclaration={styles.emptyCopy}
          >
            Select a location and the QR code will show up here.
          </Text>
        </div>
      ) : (
        <div className={css(styles.bodyContainer, styles.smallerContainer)}>
          <img
            className={css(styles.qr)}
            src={selectedLocation.signupCode.signupQrcodeLink}
            alt="qr-code"
          />
          <Text type="P2">
            Location:{" "}
            <Text type="P2" fontWeight="SemiBold">
              {selectedLocation.name}
            </Text>
          </Text>
          <Text type="P2">
            Manual sign up code:{" "}
            <Text type="P2" fontWeight="SemiBold">
              {selectedLocation.signupCode!.signupLink.split("/").pop()}
            </Text>
          </Text>
          <div className={css(styles.pickerButtons)}>
            <>
              <AutoLayout
                spacingMode="space-around"
                spaceBetweenItems={16}
                padding={"16px"}
              >
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenu.Trigger asChild>
                    <Button
                      text="Print as flyer"
                      variant="Outline"
                      onClick={() => {}}
                      rightIcon="print"
                      style={{ flexGrow: 1 }}
                    />
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content>
                      <DropdownMenu.Item
                        onSelect={() => {
                          window.open(`/group/${selectedLocation.id}/poster`);
                        }}
                        style={{ minWidth: 80 }}
                      >
                        <AutoLayout padding={12}>
                          <Text
                            type="P2"
                            fontWeight="Regular"
                            color={deprecatedTones.gray8}
                          >
                            English
                          </Text>
                        </AutoLayout>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        onSelect={() => {
                          window.open(
                            `/group/${selectedLocation.id}/spanish-poster`,
                          );
                        }}
                      >
                        <AutoLayout padding={12}>
                          <Text
                            type="P2"
                            fontWeight="Regular"
                            color={deprecatedTones.gray8}
                          >
                            Spanish
                          </Text>
                        </AutoLayout>
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu>
                <div style={{ flexGrow: 1, position: "relative" }}>
                  <Button
                    text="Copy as URL"
                    variant="Outline"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedLocation.signupCode!.signupLink,
                      );
                      setCopySuccessVisible(true);
                      setTimeout(() => {
                        setCopySuccessVisible(false);
                      }, 3000);
                    }}
                    rightIcon="copy"
                  />
                  {copySuccessVisible && (
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 1 }}
                        className={css(styles.tooltip)}
                        animate={"animateInAndOut"}
                        variants={{
                          animateInAndOut: {
                            opacity: 0,
                            transition: {
                              duration: 1.3,
                            },
                          },
                        }}
                      >
                        Copied!
                      </motion.div>
                    </AnimatePresence>
                  )}
                </div>
              </AutoLayout>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteQRCodePicker;

const styles = StyleSheet.create({
  container: {
    padding: "24px",
    paddingTop: "8px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  bodyContainer: {
    overflow: "hidden",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minWidth: "100%",
    flexDirection: "column",
    borderRadius: "16px",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    flexGrow: 1,
    padding: "100px",
  },
  smallerContainer: {
    padding: 0,
  },
  emptyImg: {
    width: "88px",
    overflow: "hidden",
  },
  emptyCopy: {
    maxWidth: "190px",
    textAlign: "center",
    marginTop: "16px",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: deprecatedTones.gray8,
  },
  qr: {
    maxWidth: "176px",
    margin: "auto",
    overflow: "hidden",
  },
  tooltip: {
    fontSize: "13px",
    position: "absolute",
    backgroundColor: deprecatedTones.blue13,
    color: deprecatedTones.white,
    display: "block",
    padding: "12px",
    bottom: "36px",
    whiteSpace: "nowrap",
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.18)",
    borderRadius: "8px",
    marginLeft: "auto",
    marginRight: "auto",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 10,
    ":after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-6px",
      width: 0,
      height: 0,
      borderTop: `solid 6px ${deprecatedTones.blue13}`,
      borderLeft: "solid 6px transparent",
      borderRight: "solid 6px transparent",
    },
  },
  pickerButtons: {
    flexDirection: "column",
    width: "100%",
    marginTop: "auto",
  },
  dropdownMenuItem: {
    borderRadius: 16,
  },
});
