import React from "react";
import Link from "next/link";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarGroupLabelIcon,
  useSidebar,
} from "@src/ui/sidebar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";
import BookOpenOutlineIcon from "@src/ui/icons/18px/book-open-outline";
import BookmarkOutlineIcon from "@src/ui/icons/18px/bookmark-outline";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import { useRouter } from "next/router";
import { useBooleanLocalStorage } from "@src/hooks/useLocalStorage";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { Ff } from "@src/types.generated";
import ClipboardIcon from "@src/ui/icons/18px/clipboard";

const AdminLeftNavLibraryMenu: React.FC = () => {
  const { pathname } = useRouter();
  const { state } = useSidebar();
  const [isOpen, setIsOpen] = useBooleanLocalStorage(
    `admin-left-nav-library-menu-open`,
    true,
  );
  const taskLibraryItemTypeFlagEnabled = useFeatureFlag(Ff.TaskLibraryItemType);
  return (
    <Collapsible
      open={state === "collapsed" ? true : isOpen}
      onOpenChange={setIsOpen}
      className="group/collapsible"
      asChild
    >
      <SidebarGroup>
        <SidebarGroupLabel asChild>
          <CollapsibleTrigger>
            Library
            <SidebarGroupLabelIcon />
          </CollapsibleTrigger>
        </SidebarGroupLabel>
        <CollapsibleContent>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip="Modules"
                  data-testid="modules-button"
                  isActive={pathname === "/library/modules"}
                >
                  <Link href="/library/modules">
                    <BookOpenOutlineIcon />
                    Modules
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip="Courses"
                  data-testid="courses-button"
                  isActive={pathname === "/library/courses"}
                >
                  <Link href="/library/courses">
                    <BookmarkOutlineIcon />
                    Courses
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip="Check-ins"
                  data-testid="check-ins-button"
                  isActive={pathname === "/library/check-ins"}
                >
                  <Link href="/library/check-ins">
                    <CircleCheckmarkOutlineIcon />
                    Check-ins
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip="Resources"
                  data-testid="resources-button"
                  isActive={pathname === "/library/resources"}
                >
                  <Link href="/library/resources">
                    <FileTextOutlineIcon />
                    Resources
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {taskLibraryItemTypeFlagEnabled && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    asChild
                    tooltip="Task Lists"
                    data-testid="tasks-button"
                    isActive={pathname === "/library/tasks"}
                  >
                    <Link href="/library/tasks">
                      <ClipboardIcon />
                      Task Lists
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
            </SidebarMenu>
          </SidebarGroupContent>
        </CollapsibleContent>
      </SidebarGroup>
    </Collapsible>
  );
};

export default AdminLeftNavLibraryMenu;
